$animationSpeed: 10s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

// Styling
.slider {
	background: white;
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 960px;
	
	&::before,
	&::after {
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 100px;
		width: 250px;
	}

	.image-box {
		height: 100px; 
		width: 150px;
		border: 2px solid #d3d3d3; 
		border-radius: 12px; 
		overflow: auto; 
		display: flex; 
		justify-content: center;
		align-items: center;
		box-sizing: border-box; 
	}
		
	.image-box img {
		height: 100%; 
		width: 100%;
		object-fit: cover; /* Ensures the image scales to fit inside the container */
		overflow: hidden;
	}

	.resize-logo img {
		object-fit: contain; /* Ensure image fits within the box */
		max-width: 85%;      /* Adjust size as needed */
		max-height: 90%;     /* Adjust size as needed */
	}
}
  
.sponsor-row-1, .sponsor-row-2, .sponsor-row-3, .sponsor-row-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}
   
.sponsor-logo-1, .sponsor-logo-2, .sponsor-logo-3, .sponsor-logo-4 {
	width: 100%; /* Adjust size as needed */
	height: 100%;
	object-fit: auto;
}

.mobile-sponsor-row-1, .mobile-sponsor-row-2, .mobile-sponsor-row-3, .mobile-sponsor-row-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
}
   
.mobile-sponsor-logo-1, .mobile-sponsor-logo-2, .mobile-sponsor-logo-3, .mobile-sponsor-logo-4 {
	width: auto; /* Adjust size as needed */
	height: 80px;
	object-fit: auto;
}

.hr {
	display: block;
    height: 1px;
    border-top: 1px solid #ccc;
}
  
.event-container {
	display: flex;
	justify-content: center; 
	align-items: center;    
	height: 20vh;            
	background-color: #fde9ec;
	border: 5px solid #993334;
	transition: 0.3s;
	width: 95ch;             
	margin: 0 auto;        
}
  
.event-image {
	max-width: 100%; 
	max-height: 100%; 
	object-fit: contain; 
	transition: opacity 0.3s ease-in-out; 
}

.event-container:hover {
	background-color: #fde9ec;
	border: 5px solid #cc8f91;
}

.event-container:hover .event-image {
	opacity: 0.5; 
}

.dropdown-content {
	display: none; /* Initially hidden */
	position: absolute;
	background-color: white;
	min-width: 160px; /* Dropdown width */
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Dropdown shadow */
	z-index: 1;
	border-radius: 8px;
  }
  
  .dropdown-content a:hover {
	background-color: #f1f1f1;
	color: #050505;
  }
  
  button {
	cursor: pointer;
	display: flex;
	align-items: center;
  }
  
  .dropdown-content {
	transition: opacity 0.3s ease-in-out;
  }
  
  


  
    