li::before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #dedede;
  display: inline-block;
  border-radius: 6px;
  position: relative;
  margin-right: 10px;
  margin-top: 3px;
  object-fit: cover;
}

div.divider {
  min-height: 60px;
  border-left: 3px solid #dedede;
  padding-left: 14.5px;
  margin-left: 4.5px;
  object-fit: cover;
}

/* width */
ul::-webkit-scrollbar {
  width: 2px;
}

/* Track */
ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
ul::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.logo-wrapper {
  border: 2px solid #dedede;
  border-radius: 8px;
  overflow: hidden;
}

.sponsor-card {
  transition: transform 0.2s ease-in-out;
}

.sponsor-card:hover {
  transform: scale(1.05);
}
